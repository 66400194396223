import { Maybe } from "graphql-tools";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";

import {
  GetUserQuery_bruger_session_BrugerSession_kan_repraesentere_Repraesentationsperson as Repraesentationsperson,
  GetUserQuery_bruger_session_BrugerSession_kan_repraesentere_Repraesentationsperson_adresser_Repraesentationsadresse as RepresenteeAddress,
  GetUserQuery_bruger_session_BrugerSession_kan_repraesentere_Repraesentationsperson_bfenumre_Repraesentationsbfe as RepresenteeBfe,
  RepraesentantRolle,
} from "@/graphql/__generated__/privateTypes";
import { authAtom } from "@/store";

export type SelectedRepresentee = {
  id: UUID | null;
  rolle: RepraesentantRolle | null;
};

export type RepresenteeProperties = {
  [id: number]: {
    bfeNumre?: Array<RepresenteeBfe>;
    address?: Array<RepresenteeAddress>;
  };
};

export interface Representee extends Repraesentationsperson {
  showRole?: boolean;
  properties?: RepresenteeProperties;
}

const useRepresentatives = () => {
  const [{ user }] = useAtom(authAtom);
  const [selectedProperty, setSelectedProperty] = useState<
    "egne-ejendomme" | Repraesentationsperson | null
  >("egne-ejendomme");

  useEffect(() => {
    if (user?.repraesenterer) {
      const isRepresenting = user?.kan_repraesentere?.filter((representee) => {
        return (
          representee?.id === user?.repraesenterer?.id &&
          representee?.rolle === user?.repraesenterer?.rolle
        );
      })[0];

      if (isRepresenting) setSelectedProperty(isRepresenting);
    }
  }, [setSelectedProperty, user]);

  const unfilteredRepresentees = React.useMemo(() => {
    const representees: Maybe<Representee>[] = user?.kan_repraesentere?.length
      ? user?.kan_repraesentere
      : [];

    function checkForDuplicates(items: Maybe<Representee>[]) {
      const nameSet = new Set();

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (!item) {
          continue;
        }

        if (!item.navn) {
          item.showRole = true;
          continue;
        }

        if (nameSet.has(item.navn)) continue;

        for (let j = i + 1; j < items.length; j++) {
          const otherItem = items[j];

          if (item?.navn === otherItem?.navn) {
            item.showRole = true;
            otherItem.showRole = true;
          }
        }

        nameSet.add(item.navn);
      }
    }

    function sortByName(a: Maybe<Representee>, b: Maybe<Representee>) {
      if (a?.navn && b?.navn) {
        return a.navn.localeCompare(b.navn);
      } else if (a?.navn) {
        return -1;
      } else if (b?.navn) {
        return 1;
      }

      return 0;
    }

    function combineAddressAndBfe(
      representee: Repraesentationsperson
    ): RepresenteeProperties {
      const combineAddressAndBfe: RepresenteeProperties = [];

      if (representee.adresser) {
        for (let index = 0; index < representee.adresser.length; index++) {
          const address = representee.adresser[index];

          if (!address || !address.vurderingsejendom_id) continue;

          if (!combineAddressAndBfe[address.vurderingsejendom_id]) {
            combineAddressAndBfe[address.vurderingsejendom_id] = {
              address: [],
              bfeNumre: [],
            };
          }

          combineAddressAndBfe[address.vurderingsejendom_id].address?.push(
            address
          );
        }
      }

      if (representee.bfenumre) {
        for (let index = 0; index < representee.bfenumre.length; index++) {
          const bfeNumber = representee.bfenumre[index];

          if (!bfeNumber || !bfeNumber.vurderingsejendom_id) continue;

          if (!combineAddressAndBfe[bfeNumber.vurderingsejendom_id]) {
            combineAddressAndBfe[bfeNumber.vurderingsejendom_id] = {
              address: [],
              bfeNumre: [],
            };
          }

          combineAddressAndBfe[bfeNumber.vurderingsejendom_id].bfeNumre?.push(
            bfeNumber
          );
        }
      }

      return combineAddressAndBfe;
    }

    checkForDuplicates(representees);

    for (let index = 0; index < representees.length; index++) {
      const representee = representees[index];

      if (!representee) continue;

      representee.properties = combineAddressAndBfe(representee);
    }

    const representeesWithLatestDocumentActivity = representees.filter(
      (r) => r?.seneste_dokument_aktivitet
    );
    const representeesWithoutLatestDocumentActivity = representees.filter(
      (r) => !r?.seneste_dokument_aktivitet
    );

    representeesWithLatestDocumentActivity.sort((a, b) => {
      if (a?.seneste_dokument_aktivitet && b?.seneste_dokument_aktivitet) {
        const dateA = new Date(a.seneste_dokument_aktivitet).getTime();
        const dateB = new Date(b.seneste_dokument_aktivitet).getTime();

        if (dateA !== dateB) {
          return dateB - dateA;
        } else {
          return sortByName(a, b);
        }
      } else if (a?.seneste_dokument_aktivitet) {
        return -1;
      } else if (b?.seneste_dokument_aktivitet) {
        return 1;
      }

      return 0;
    });

    representeesWithoutLatestDocumentActivity.sort((a, b) => sortByName(a, b));

    return [
      ...representeesWithLatestDocumentActivity,
      ...representeesWithoutLatestDocumentActivity,
    ];
  }, [user?.kan_repraesentere]);

  return {
    user,
    selectedProperty,
    setSelectedProperty,
    unfilteredRepresentees,
  };
};

export default useRepresentatives;
