import Fuse, { IFuseOptions } from "fuse.js";
import { Maybe } from "graphql-tools";
import React from "react";

import { Representee } from "./useRepresentatives";

type Props = {
  unfilteredRepresentees: Maybe<Representee>[];
  maxResults: number;
};

function useRepresenteesSearch({ unfilteredRepresentees, maxResults }: Props) {
  const [searchString, setSearchString] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);

  const fuse = React.useMemo(() => {
    const fuseOptions = {
      threshold: 0.1,
      distance: 500,
      includeScore: true,
      keys: [
        "navn",
        "properties.bfeNumre.bfe_nummer",
        "properties.address.adressebetegnelse",
      ],
    } as IFuseOptions<Maybe<Representee>>;

    const fuse = new Fuse(unfilteredRepresentees, fuseOptions);

    return fuse;
  }, [unfilteredRepresentees]);

  const filteredRepresentees = React.useMemo((): Maybe<Representee>[] => {
    if (searchString === "") {
      return unfilteredRepresentees;
    }

    const results = fuse.search(searchString);

    return results.map((result) => result.item);
  }, [fuse, searchString, unfilteredRepresentees]);

  const pagedRepresentees = React.useMemo(() => {
    const sliceStart = currentPage * maxResults;
    const sliceEnd = sliceStart + maxResults;

    const pagedRepresentees = filteredRepresentees.slice(sliceStart, sliceEnd);

    return pagedRepresentees;
  }, [currentPage, filteredRepresentees, maxResults]);

  const totalPages = React.useMemo(() => {
    return filteredRepresentees.length > 0
      ? Math.ceil(filteredRepresentees.length / maxResults)
      : 0;
  }, [filteredRepresentees.length, maxResults]);

  const goToPage = React.useCallback(
    (pageNumber: number) => {
      if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      }

      if (pageNumber < 0) {
        setCurrentPage(0);
      }

      setCurrentPage(pageNumber);
    },
    [totalPages]
  );

  return {
    searchString,
    setSearchString,
    currentPage: currentPage,
    goToPage: goToPage,
    totalPages: totalPages,
    pagedRepresentees: pagedRepresentees,
  };
}

export default useRepresenteesSearch;
