import useElementSize from "@charlietango/use-element-size";
import useMedia from "@charlietango/use-media";
import { Maybe } from "graphql-tools";
import { useAtom } from "jotai";
import { rem } from "polished";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Grid from "@/components/Grid/Grid";
import { FieldBase } from "@/form/Input/input-styles";
import RadioButton from "@/form/RadioButton/RadioButton";
import { Repraesentationsperson } from "@/graphql/__generated__/privateTypes";
import CloseIcon from "@/icons/CloseXIcon";
import SearchIcon from "@/icons/SearchIcon";
import { showRepresentativeAccessModalAtom } from "@/store";
import { breakpoints, mediaQuery } from "@/styles/media";
import { color } from "@/styles/theme";
import useRepresentatives, {
  Representee,
} from "@/utils/hooks/useRepresentatives";
import useRepresenteesSearch from "@/utils/hooks/useRepresenteesSearch";
import {
  formatBFENumbersWithPlural,
  timeFormatter,
} from "@/utils/string-formatting";
import { LinkViewModel } from "@/view-models/LinkViewModel";
import { RepresentativeModalViewModel } from "@/view-models/RepresentativeModalViewModel";

import Button, { ButtonVariation } from "../Button/Button";
import Heading from "../Heading/Heading";
import PagingControls from "../PagingControls/PagingControls";
import Richtext from "../Richtext/Richtext";
import Modal from "./Modal";

export type ActionType = LinkViewModel & {
  onClick?: () => void;
  variation: ButtonVariation;
  submitting?: boolean;
};

export type Props = {
  children?: React.ReactNode;
  onRequestClose: () => void;
  onClosed?: () => void;
  primaryAction?: ActionType;
  actions?: Array<ActionType>;
  disableWrapperMaxHeight?: boolean;
  onSelectRepresentedOwner: (
    representedOwner: "egne-ejendomme" | Repraesentationsperson | null
  ) => void;
} & RepresentativeModalViewModel;

type StyledWrapperProps = {
  hasActions?: boolean;
};

const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.hasActions ? "290px" : "380px")};
  background-color: ${color.white};
  overflow: auto;
  height: 100vh;

  ${mediaQuery.sm`
    max-height: calc(100vh - 160px);
  `};
`;

type StyledContentProps = { closeBarHidden: boolean };

const StyledContent = styled.div<StyledContentProps>`
  padding: 0 20px 20px 20px;
  ${(p) => p.closeBarHidden && "padding-top: 20px;"}

  @media only screen and (min-width: ${breakpoints.sm}em),
    screen and (min-height: 516px) {
    position: relative;
    padding: 46px 20px 20px 20px;
  }
`;

const StyledDateLabel = styled.p`
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${color.grey};
`;

const StyledModalFooter = styled(Grid)`
  width: 100%;
  background-color: ${color.white};
  padding-bottom: 200px;

  ${mediaQuery.sm`
    padding-bottom: 110px;
  `};
`;

const StyledNavigation = styled.div`
  border-top: 1px solid ${color.light};
  width: 100%;
  padding: 20px;
  display: flex;
  min-height: 90px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  background-color: ${color.white};
  bottom: 0;

  ${mediaQuery.sm`
    flex-direction: row-reverse;
  `};
`;

type StyledTableProps = { height: null | number };

const StyledTable = styled.table<StyledTableProps>`
  ${(p) => (p.height ? `height: ${p.height}px;` : null)}
  width: 100%;
  margin-top: 20px;
`;

type StyledDropdownIconProps = { active?: boolean };

export const StyledDropdownIcon = styled.span<StyledDropdownIconProps>`
  color: ${color.blue};
  flex: 0 0 12px;
  z-index: 1;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

type StyledTrProps = { border: boolean };

const StyledTr = styled.tr<StyledTrProps>`
  ${(p) =>
    p.border ? `border-bottom: 1px solid ${color.paleLilac}` : undefined};
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
`;

const StyledTh = styled.th`
  font-size: ${rem(14)};
  color: ${color.altBlue};
  font-weight: bold;
`;

const RepresenteeStyledTr = styled(StyledTr)`
  padding: 21px 0;
`;

type StyledTdProps = { fullWidth?: boolean };

const StyledTd = styled.td<StyledTdProps>`
  font-size: ${rem(14)};
  color: ${color.grey};
  ${(p) => (p.fullWidth ? "width: 100%;" : null)}
`;

const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSearchField = styled.input`
  ${FieldBase}
  border: none;
  padding-top: 0;
  padding-bottom: 0;

  &:focus {
    outline: none;
  }
`;

type StyledButtonProps = { visible: boolean };

const StyledButton = styled.span<StyledButtonProps>`
  cursor: pointer;
  color: ${color.blue};
  ${(p) => (p.visible ? null : "display: none;")}
`;

type StyledActionButtonProps = { variation?: string };

const StyledActionButton = styled(Button)<StyledActionButtonProps>`
  color: ${(props) =>
    props.variation === "text" ? `${color.grey}` : undefined};

  ${mediaQuery.sm`
    flex: 0 1 ${(props) => (props.variation === "text" ? "100px" : "140px")};
    margin-right: 10px;

    &:first-child {
      flex: 0 1 ${(props) => (props.variation === "text" ? "100px" : "150px")};
      margin-right: 0;
    }
  `};
`;

const StyledRepresenteeList = styled.tbody`
  display: block;
  padding-bottom: 20px;
`;

const StyledRepresenteeSearch = styled.tbody`
  display: block;
`;

const StyledRadioButton = styled(RadioButton)`
  width: 100%;

  input,
  svg {
    align-self: flex-start;
    margin-top: 4px;
  }

  & > span {
    width: 100%;
  }
`;

const StyledRepresenteeColumn = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const StyledRepresenteeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledPropertyNumber = styled.span`
  font-size: ${rem(16)};
  align-self: center;
  color: ${color.grey};
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type StyledNameProps = {
  selected: boolean;
};

const StyledName = styled.span<StyledNameProps>`
  ${(p) => (p.selected ? `font-weight: 600` : undefined)};
  max-width: 300px;
`;

const RepresentativeAccessModal = ({
  onClosed,
  children,
  primaryAction,
  actions,
  disableWrapperMaxHeight = false,
  listHeader,
  latestDocumentChangeLabel,
  searchPlaceholder,
  title,
  description,
  companyOwnerLabel,
  onSelectRepresentedOwner,
  ownPropertiesLabel,
  multiplePropertiesLabel,
  multipleAddressesLabel,
}: Props) => {
  const [ref, size] = useElementSize();
  const isDesktop = useMedia(`(min-width: ${breakpoints.sm}em)`);
  const { t } = useTranslation(["common", "search"]);

  const {
    user,
    selectedProperty,
    setSelectedProperty,
    unfilteredRepresentees,
  } = useRepresentatives();

  const {
    currentPage,
    goToPage,
    totalPages,
    pagedRepresentees,
    setSearchString,
    searchString,
  } = useRepresenteesSearch({
    unfilteredRepresentees,
    maxResults: 10,
  });

  const [representativeModalOpen, setRepresentativeModalOpen] = useAtom(
    showRepresentativeAccessModalAtom
  );

  const [tableSize, setTableSize] = React.useState<null | number>(null);

  useEffect(() => {
    // Size initially returns 0 so we need to react to it. But we can't update the value
    // So we only do once when tableSize is null
    if (size.height > 0 && tableSize === null) {
      setTableSize(size.height);
    }
  }, [tableSize, setTableSize, size]);

  const representativeNameAndLabel =
    user?.sagsperson?.type === "cvr"
      ? `${user?.sagsperson?.navn ?? ""} ${companyOwnerLabel}`
      : `${user?.navn ?? ""} ${ownPropertiesLabel}`;

  return (
    <Modal
      ariaLabel={title ? title : t("modalAriaLabel")}
      closeOnBackdropClicked={!!user?.repraesenterer}
      heightBreakPoint={515}
      hideModalCloseBar={!user?.repraesenterer}
      isOpen={representativeModalOpen}
      onClosed={onClosed}
      onRequestClose={() => setRepresentativeModalOpen(false)}
      showPip={true}
    >
      <StyledForm onSubmit={(e) => e.preventDefault()}>
        <StyledWrapper hasActions={!!actions}>
          <StyledContent closeBarHidden={!user?.repraesenterer}>
            <Heading level={4} tag="h2">
              {title}
            </Heading>
            <Richtext size="small" source={description} />
            <StyledTable ref={ref} height={tableSize}>
              <StyledRepresenteeSearch>
                <StyledTr border={false}>
                  <StyledTd>
                    <StyledRadioButton
                      checked={selectedProperty === "egne-ejendomme"}
                      name={"selectedProperty"}
                      onChange={(e) => setSelectedProperty(e.target.value)}
                      value="egne-ejendomme"
                    >
                      <StyledName
                        selected={selectedProperty === "egne-ejendomme"}
                      >
                        {representativeNameAndLabel}
                      </StyledName>
                    </StyledRadioButton>
                  </StyledTd>
                </StyledTr>

                <StyledTr border={false} id="representeesList">
                  <StyledTh>{listHeader}</StyledTh>
                </StyledTr>

                <StyledTr border>
                  <StyledTd fullWidth={true}>
                    <StyledSearchContainer>
                      <StyledDropdownIcon>
                        <SearchIcon height={"16"} width={"16"} />
                      </StyledDropdownIcon>
                      <StyledSearchField
                        aria-label={searchPlaceholder}
                        onChange={(e) => setSearchString(e.currentTarget.value)}
                        placeholder={searchPlaceholder}
                        role={"search"}
                        type="input"
                        value={searchString}
                      />
                      <StyledButton
                        aria-disabled={searchString.length === 0}
                        aria-label={`${t("closeSearchBarIconLabel")} ${t(
                          "ariaSearchText"
                        )}`}
                        onClick={() => setSearchString("")}
                        role="button"
                        tabIndex={0}
                        visible={searchString.length > 0}
                      >
                        <CloseIcon size="small" />
                      </StyledButton>
                    </StyledSearchContainer>
                  </StyledTd>
                </StyledTr>
              </StyledRepresenteeSearch>
              <StyledRepresenteeList>
                {pagedRepresentees?.map((representee, index) => (
                  <RepresenteeRow
                    key={index}
                    latestDocumentChangeLabel={latestDocumentChangeLabel}
                    multipleAddressesLabel={multipleAddressesLabel}
                    multiplePropertiesLabel={multiplePropertiesLabel}
                    representee={representee}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                  />
                ))}
              </StyledRepresenteeList>
            </StyledTable>

            {children}
          </StyledContent>
          <StyledModalFooter>
            <PagingControls
              currentPage={currentPage}
              goToPageCallback={goToPage}
              siblings={0}
              totalPages={totalPages}
            />
            {(actions || primaryAction) && (
              <StyledNavigation>
                {actions &&
                  actions.map((action, key) => {
                    const { ...rest } = action;

                    return (
                      <StyledActionButton
                        key={key}
                        {...rest}
                        fillWidth={!isDesktop}
                        // @ts-ignore
                        onClick={() => {
                          if (action.variation === "primary") {
                            onSelectRepresentedOwner(selectedProperty);
                          } else if (action.onClick) {
                            action.onClick();
                          } else {
                            setRepresentativeModalOpen(false);
                          }
                        }}
                        type={
                          action.variation === "primary" ? "submit" : "button"
                        }
                      />
                    );
                  })}
              </StyledNavigation>
            )}
          </StyledModalFooter>
        </StyledWrapper>
      </StyledForm>
    </Modal>
  );
};

type RepresenteeRowProps = {
  setSelectedProperty: React.Dispatch<
    React.SetStateAction<Repraesentationsperson | "egne-ejendomme" | null>
  >;
  representee: Maybe<Representee>;
  selectedProperty: Repraesentationsperson | "egne-ejendomme" | null;
  latestDocumentChangeLabel: string;
  multipleAddressesLabel: string;
  multiplePropertiesLabel: string;
};

function RepresenteeRow({
  setSelectedProperty,
  representee,
  selectedProperty,
  latestDocumentChangeLabel,
  multiplePropertiesLabel,
  multipleAddressesLabel,
}: RepresenteeRowProps) {
  const [t, , ready] = useTranslation("noaddress");

  if (!representee) return null;

  const vurderingsejendomId =
    representee.adresser?.[0]?.vurderingsejendom_id ??
    representee.bfenumre?.[0]?.vurderingsejendom_id;

  let addressString: string | null = "";
  let bfeString: string | null = "";

  if (vurderingsejendomId && representee.properties) {
    addressString =
      representee.properties[vurderingsejendomId].address?.[0]
        ?.adressebetegnelse ?? "";

    const bfeNumbers = representee.properties[
      vurderingsejendomId
    ]?.bfeNumre?.map((bfe) => bfe.bfe_nummer);

    bfeString = formatBFENumbersWithPlural(
      bfeNumbers,
      t("andOthersLabel"),
      t("bfeNumberLabel"),
      t("bfeNumbersLabel")
    );
  }

  //midlertidig
  const multipleProperties =
    representee?.adresser && representee?.adresser?.length > 1;

  //midlertidig
  const propertiesCount = representee?.adresser?.length;

  const selected =
    selectedProperty !== "egne-ejendomme" &&
    selectedProperty?.id === representee.id &&
    selectedProperty?.rolle === representee.rolle;

  return (
    <RepresenteeStyledTr border>
      <StyledTd
        fullWidth
        onClick={() =>
          setSelectedProperty({
            id: representee.id,
            rolle: representee.rolle,
          })
        }
      >
        <StyledRadioButton
          checked={selected}
          name={"selectedProperty"}
          onChange={() => setSelectedProperty(representee)}
          value={representee.navn ? representee.navn : "Ukendt"}
        >
          <StyledRepresenteeInfo>
            <StyledRepresenteeColumn>
              <StyledName selected={selected}>
                {representee.navn ? representee.navn : "Ukendt"}
                {representee.showRole ? ` (${representee.rolle})` : null}
              </StyledName>

              <span>
                {representee?.adresser && representee?.adresser?.length > 0
                  ? addressString
                  : ready && bfeString}
                {multipleProperties && ` (${multipleAddressesLabel})`}
              </span>

              {representee.seneste_dokument_aktivitet ? (
                <StyledDateLabel>
                  {latestDocumentChangeLabel}{" "}
                  {timeFormatter(representee.seneste_dokument_aktivitet)}
                </StyledDateLabel>
              ) : null}
            </StyledRepresenteeColumn>

            {multipleProperties && (
              <StyledPropertyNumber>
                {`${propertiesCount} ${multiplePropertiesLabel}`}
              </StyledPropertyNumber>
            )}
          </StyledRepresenteeInfo>
        </StyledRadioButton>
      </StyledTd>
    </RepresenteeStyledTr>
  );
}

RepresentativeAccessModal.displayName = "RepresentativeAccessModal";

export default RepresentativeAccessModal;
