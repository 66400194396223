import { useAtom } from "jotai";
import * as React from "react";

import { RouteContext } from "@/App/Router";
import { metaAtom } from "@/store";

function getInputValueFromUrl(name) {
  // function that reads params from current url
  const url = window.location.href;
  const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(url);
  if (results == null) return null;

  return decodeURI(results[1]) || "";
}

function overwriteWidgetConfig(originalConfig) {
  // function that mutates the widget config
  // We insert values from the url, and make the widget open up automatically
  const newConfig = originalConfig;

  // for simplicity, our url parameter names must match the "Input Name" of the input field
  // for this example, we have 2 fields "name" and "email" which we want to fill out.
  // The url could something like https://example.com/my-subpage?name=John&email=j.doe@example.com
  const inputFields = newConfig.visitorInfo.fields;
  if (Array.isArray(inputFields) && inputFields.length) {
    inputFields.forEach((field, index) => {
      const fieldName = field.name; // this will be either "name" or "email" in the example
      const fieldValueFromUrl = getInputValueFromUrl(fieldName); // value from the url

      if (fieldValueFromUrl !== "") {
        // if there is a value found then we want to insert it
        inputFields[index].defaultValue = fieldValueFromUrl;
      }
    });
  }

  // make the widget open automatically and skip the start page
  newConfig.showStartPage = false;
  newConfig.startOpen = true;

  // return the updated config that the widget should use instead
  return newConfig;
}

const ChatBotInjector = () => {
  const [meta] = useAtom(metaAtom);
  const { push } = React.useContext(RouteContext);

  React.useEffect(() => {
    function initializeChatbot(overwrite?: boolean) {
      if (!overwrite) {
        // @ts-ignore
        (window as any).widgetObj = configureWidget();
      } else {
        // we add our overwrite function as the first argument to configureWidget
        // @ts-ignore
        (window as any).widgetObj = configureWidget(overwriteWidgetConfig);
      }
    }

    if (meta.behindAuth || meta.disableChatBot) {
      if (!(window as any).widgetObj) {
        return;
      }

      const widgetObj = Promise.resolve((window as any).widgetObj);
      widgetObj.then((obj) => {
        obj.destroyWidget();
      });
    } else {
      if ((window as any).widgetObj) {
        return;
      }

      const widgetScriptExists = document.getElementById(
        "sw-supchat-widget-script"
      );

      if (widgetScriptExists) {
        widgetScriptExists.remove();
      }

      const widgetScript = document.createElement("script");
      widgetScript.id = "sw-supchat-widget-script";
      widgetScript.src =
        "https://supchat.skat.supwizapp.com/widget/main.js?config_id=13";
      widgetScript.onload = () => {
        initializeChatbot(meta.enableChatBotTransfer);
      };
      document.body.append(widgetScript);
    }
  }, [meta, push]);

  return <></>;
};

export default ChatBotInjector;
