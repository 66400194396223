// GENERATED FILE
// This file is automatically generated by "create-modules.ts" whenever you add or remove a module.
import { ModuleNameEnum } from "@/view-models/enums/ModuleNameTypes";

const modulesList: ModuleNameEnum[] = [
  "Accordion",
  "AnchorMenu",
  "AssessmentExplanation",
  "Breadcrumbs",
  "CategoryList",
  "CludoSearchResults",
  "Compensation",
  "CompensationRouter",
  "Contact",
  "ContactAndObjectionContentSection",
  "ContactAndObjectionFormIntro",
  "ContactAndObjectionHero",
  "ContactAndObjectionInfo",
  "ContactAndObjectionIntro",
  "ContactAndObjectionReceipt",
  "ContactCategories",
  "ContactForm",
  "ContactStartLink",
  "DisplayList",
  "DisplayListGroup",
  "DisplayListGroupSection",
  "ErrorPage",
  "FactsBox",
  "Footer",
  "GroundValue",
  "GuidesOverview",
  "Header",
  "HeaderBanner",
  "HelpPanel",
  "Hero",
  "HistoricAssessmentComplaintDeadline",
  "Introduction",
  "LoginRouter",
  "Media",
  "MunicipalityLookup",
  "MyProperties",
  "MyPropertyAssessmentComplaint",
  "MyPropertyBuildingSection",
  "MyPropertyDocuments",
  "MyPropertyGroundSection",
  "MyPropertyGroundValueCondoSection",
  "MyPropertyHero",
  "MyPropertyIntroAssessment",
  "MyPropertyIntroDeclaration",
  "MyPropertyObjectionSection",
  "MyPropertySurroundingsSection",
  "ObjectionCategories",
  "ObjectionForm",
  "ObjectionSend",
  "ObjectionSummary",
  "PreliminaryLookup",
  "PreliminaryPropertiesOverview",
  "PreliminaryPropertyIntroAssessment",
  "ProfileBanner",
  "PromotedContent",
  "PromotedContentList",
  "PropertySearch",
  "PropertyTypeModal",
  "PropertyValue",
  "PublicPropertyHero",
  "PublicPropertyIntroAssessment",
  "Quote",
  "ReferenceProperty",
  "ReferencePropertyLookup",
  "RelatedPages",
  "ResetCookies",
  "RichTextModule",
  "SearchResults",
  "StatusBar",
  "StepByStepGuide",
  "SubjectOverview",
  "SurveyModal",
  "TaxCalculator",
  "Timeline",
  "TimelineGroup",
  "VideoList",
];

export default modulesList;
